import { useTheme } from "@emotion/react";
import {
  Avatar,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { GlassMagnifier } from "react-image-magnifiers";
import { useNavigate, useParams } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import {
  Files,
  LeaderBoard,
  TDTools,
  etherScan,
  ship,
  tutorials,
} from "../assets/images";
import { BackButton, Header, NftContactLinks } from "../components";
import FooterHome from "../components/footer/FooterHome";
import MobileFooter from "../components/MobileFooter";
import MobileHeader from "../components/MobileHeader";
import { useLoader } from "../context/loaderContext";
import { getNftById, getTraitsById } from "../services/clientService";
import { getCollectionDetails } from "../services/openSeaService";

const NftModelDetailsNew = () => {
  const { showLoader, hideLoader } = useLoader();
  const layoutOptions = [
    {
      id: 0,
      name: "3D TOOLS",
      icon: TDTools,
      route: "3dTools",
      onClick: () => {},
    },
    {
      id: 1,
      name: "Files",
      icon: Files,
      route: "files",
      onClick: () => {},
    },

    {
      id: 9,
      name: "Leader Board",
      icon: LeaderBoard,
      route: "analytics",
      onClick: () => {},
    },
    {
      id: 10,
      name: "Tutorials",
      icon: tutorials,
      route: "tutorials",
      onClick: () => {},
    },
  ];
  const { palette, ...theme } = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { id, nftId } = useParams();
  const [specificImageData, setSpecificImageData] = useState();
  const [nftAddress, setNftAddress] = useState({
    holder: "",
    id: "",
    contractAddress: "",
  });
  const [traits, setTraits] = useState([
    {
      value: "TRAITS",
      floorAskPrices: ["FLOOR"],
      onSaleCount: "LISTED",
      tokenCount: "SUPPLY",
    },
  ]);

  const [tabButtons, setTabButtons] = useState([
    {
      name: "Traits",
      selected: true,
    },
    {
      name: "Delegations",
      selected: false,
    },
  ]);
  const isSmall = useMediaQuery("(max-width:900px)");
  const getAddress = () => {
    if (id === "boredapeyachtclub") {
      return specificImageData?.address;
    } else {
      return nftAddress?.holder;
    }
  };

  const handleCopy = () => {
    const address = getAddress();
    if (address) {
      navigator.clipboard.writeText(address);
      enqueueSnackbar("Copied!", {
        variant: "success",
      });
    }
  };

  const displayAddress = () => {
    const address = getAddress();
    if (address) {
      return `${address.slice(0, 4)}.....${address.slice(address.length - 4)}`;
    }
    return "";
  };
  const getNftData = async (event) => {
    try {
      const res = await getNftById(nftId, id);
      if (res) {
        setSpecificImageData(res);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getNftMetaData = async (event) => {
    try {
      const collectionData = await getCollectionDetails(
        id,
        showLoader,
        hideLoader
      );
      if (collectionData) {
        setNftAddress({
          contractAddress: collectionData?.contracts[0]?.address,
          holder: collectionData?.owner,
        });
        const response = await getTraitsById(
          collectionData?.contracts[0]?.address,
          nftId
        );
        setTraits([...traits, ...response?.attributes]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const connections = [
    {
      id: 4,
      icon: (
        <img
          style={{ width: isSmall ? "16px" : "1vw" }}
          alt="DashboUGC etherscan"
          src={etherScan}
        />
      ),
      onClick: () => {
        window.open(
          "https://etherscan.io/token/" + nftAddress?.contractAddress,
          "_blank"
        );
      },
    },
    {
      id: 5,
      icon: (
        <img
          alt="DashboUGC otherpage"
          style={{ width: isSmall ? "16px" : "0.8vw" }}
          src={
            "https://storage.googleapis.com/3d-container/NFTDetailPageIcons/otherpage-dark.svg"
          }
        />
      ),
      onClick: () => {
        window.open(
          `https://app.other.page/${nftAddress?.contractAddress}/${nftId}?hideMarqueeNav=true/`,
          "_blank"
        );
      },
    },
  ];
  const buyNowConnections = [
    {
      id: 1,
      icon: (
        <img
          alt="DashbUGC magiceden"
          style={{ width: isSmall ? "16px" : "1.2vw" }}
          src={"https://storage.googleapis.com/3d-container/magiceden.png"}
        />
      ),
      onClick: () => {
        window.open(
          `https://magiceden.io/collections/ethereum/${nftAddress?.contractAddress}?evmItemDetailsModal=1~${nftAddress?.contractAddress}~${nftId}`,
          "_blank"
        );
      },
    },
    {
      id: 2,
      icon: (
        <img
          style={{ width: isSmall ? "16px" : "1vw" }}
          src={ship}
          alt="ethreum dashboUGC"
        />
      ),
      onClick: () => {
        window.open(
          "https://opensea.io/assets/ethereum/" +
            nftAddress?.contractAddress +
            "/" +
            nftId,
          "_blank"
        );
      },
    },
    {
      id: 3,
      icon: (
        <img
          alt="looksrare"
          style={{ width: isSmall ? "16px" : "1vw" }}
          src="https://ishrostorage.blob.core.windows.net/container-3d/Looks%20rare.png"
        />
      ),
      onClick: () => {
        window.open(
          "https://looksrare.org/collections/" +
            nftAddress?.contractAddress +
            "/" +
            nftId,
          "_blank"
        );
      },
    },
    {
      id: 4,
      icon: (
        <img
          alt="Blur "
          style={{ width: isSmall ? "16px" : "1vw" }}
          src="https://ishrostorage.blob.core.windows.net/container-3d/Blur icon.png"
        />
      ),
      onClick: () => {
        window.open(
          "https://blur.io/eth/asset/" +
            nftAddress?.contractAddress +
            "/" +
            nftId,
          "_blank"
        );
      },
    },
  ];
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    getNftData();
    getNftMetaData();
  }, []);
  return (
    <Box
      sx={{
        backgroundPosition: "bottom",
        backgroundSize: "cover",
        backgroundImage:
          "url('https://storage.googleapis.com/3d-container/bg.png')",
      }}
    >
      {isMobile ? <MobileHeader></MobileHeader> : <Header hamburger={true} />}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            width: "96%",
            marginTop: "5vw",
            marginBottom: "2vh",
            border: "1px solid #FFB800",
            borderRadius: "0.8vw",
            background: "rgba(0,0,0,0.2)",
            backdropFilter: "blur(18px)",
            padding: 2,
            height: {
              xl: "79vh",
              lg: "80vh",
              md: "75vh",
              xs: "auto",
              sm: "auto",
            },
            [theme.breakpoints.down("lg")]: {
              
              marginTop: "10vw",
              
            },
          }}
        >
          <Grid
            container
            spacing={0}
            alignItems="flex-start"
            justifyContent="flex-start"
            // sx={{
            //   minHeight: {
            //     xl: "79vh",
            //     lg: "73vh",
            //     md: "68vh",
            //     xs: "auto",
            //     sm: "auto",
            //   },
            // }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              alignItems="flex-start"
              justifyContent="center"
            >
              <Box>
                <BackButton />
                {/* <Typography
                  onClick={() => navigate(-1)}
                  sx={{
                    fontWeight: "800",
                    fontFamily: "var(--font-f_r)",
                    fontSize: "1.5vw",
                    textAlign: "left",
                    cursor: "pointer",
                    textTransform: "capitalize",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "14px",
                      marginRight: "0px",
                    },
                    color: "#FFB800",
                    marginRight: "5px",
                  }}
                >
                  {title}
                </Typography> */}
                <Box
                  width="100%"
                  sx={{
                    [theme.breakpoints.down("sm")]: {
                      display: "flex",
                      flexWrap: "wrap",
                    },
                  }}
                >
                  {layoutOptions?.map((option, i) => {
                    return (
                      <Box
                        key={i}
                        onClick={() => {
                          navigate(option?.route);
                        }}
                        sx={{
                          cursor: "pointer",
                          fontWeight: "600",
                          padding: 2,
                          margin: 2,
                          [theme.breakpoints.down("sm")]: {
                            width: "40%",
                          },

                          [theme.breakpoints.down("lg")]: {
                            fontSize: "12px",
                           
                             
                            marginLeft: "-10px",
                          },
                          height: "45px",
                          borderRadius: "0.5vw",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",

                          color: "transparent",
                          "&:hover": {
                            ".menus-text": {
                              color: "#000",
                            },
                            img: {
                              WebkitFilter: "invert(100%)",
                              filter: "invert(100%)",
                            },
                            background: "#FFB800",
                          },
                        }}
                      >
                        <img width="30px" 
                       
                        
                        src={option.icon} alt="icon" />
                        <Typography
                          className="menus-text"
                          sx={{
                            ml: 1,
                            fontWeight: "500",
                            fontFamily: "var(--font-i_r)",
                            fontSize: {
                              xl: "1.2em",
                              lg: "1em",
                              md: "0.8em",
                              xs: "1em",
                              sm: "1em",
                            },
                            textTransform: "capitalize",
                            textAlign: "start",
                            color: "#fff",
                            [theme.breakpoints.down("sm")]: {
                              fontSize: "14px",
                            },
                            [theme.breakpoints.down("lg")]: {
                           




                             // padding: "0px",
                            
                            },
                          }}
                        >
                          {option.name}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={10}
              lg={10}
              xl={10}
              sx={{
                backgroundColor: "#000",
                borderRadius: "15px",
                height: {
                  xl: "76vh",
                  lg: "76vh",
                  md: "65vh",
                  xs: "auto",
                  sm: "auto",
                },
              }}
            >
              <Box
                sx={{
                  // border: {
                  //   lg: "1px solid #FFB800",
                  //   md: "1px solid #FFB800",
                  //   xl: "1px solid #FFB800",
                  // },
                  width: {
                    xl: "80vw",
                    md: "80vw",
                    lg: "80vw",
                    xs: "95vw",
                  },
                  display: "flex",
                  // alignItems: "center",

                  backdropFilter: "blur(10px)",
                  justifyContent: "space-between",
                  padding: 1,
                  borderRadius: "0.8vw",
                  [theme.breakpoints.down("md")]: {
                    mt: 1,
                    width: "100%",
                    minWidth: "300px",
                    borderRadius: "20px",
                    flexDirection: "column",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "23%",
                    height: "100%",
                    maxHeight: "75vh",
                    borderRadius: "20px",
                    padding: "20px",
                    border: "1px solid #2b2b2b",
                    [theme.breakpoints.down("md")]: {
                      width: "50%",
                     
                      flexDirection: "column",
                      
                     
                    },
                    [theme.breakpoints.down("lg")]: {
                      width: "40%",
                     padding: "10px",
                     marginRight:"9px",
                      flexDirection: "column",
                      justifyContent:"space-between",
                     
                    },
                  }}
                >
                  <Typography
                    className="menus-text"
                    sx={{
                      fontWeight: "700",
                      textTransform: "capitalize",
                      fontFamily: "var(--font-f_r)",
                      fontSize: "1.5vw",
                      // marginTop: "0.9vw",
                      color: "#fff",
                      opacity: 0.7,
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "14px",
                      },
                    }}
                  >
                    {specificImageData?.name}
                  </Typography>
                  <Typography
                    className="menus-text"
                    sx={{
                      fontWeight: "700",
                      textTransform: "capitalize",
                      fontFamily: "var(--font-f_r)",
                      fontSize: "1.5vw",
                      // marginTop: "0.9vw",
                      color: "#fff",
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "14px",
                        width: "100%",
                      },
                    }}
                  >
                    # {specificImageData?.nftId}
                  </Typography>
                  <Box
                    sx={{
                      borderRadius: "0.5vw",
                      background: "#95959529",
                      height: "75%",
                      // minHeight: "600px",
                      // maxHeight: "300px",
                    }}
                  >
                    {specificImageData?.image && (
                      <GlassMagnifier
                        magnifierBorderSize={3}
                        magnifierSize="60%"
                        style={{
                          width: "100%",
                          height: "72%",
                          objectFit: "contain",
                          borderTopLeftRadius: "0.5vw",
                          borderTopRightRadius: "0.5vw",
                          overflow: "hidden",
                        }}
                        imageSrc={specificImageData?.image}
                        imageAlt={specificImageData?.nftId}
                        largeImageSrc={specificImageData?.image} // Optional
                      />

                      // <img
                      //   style={{
                      //     width: "100%",
                      //     objectFit: "contain",
                      //     borderTopLeftRadius: "0.5vw",
                      //     borderTopRightRadius: "0.5vw",
                      //     overflow: "hidden",
                      //   }}
                      //   src={specificImageData?.image}
                      // />
                    )}

                    <Box
                      mx={1}
                      my={0.6}
                      display="flex"
                      justifyContent="space-between"
                      flexWrap="wrap"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Avatar
                          sx={{
                            width: "26px",
                            height: "26px",
                            mb: { xl: 2, lg: 1, md: 1 },
                          }}
                          src={specificImageData?.image}
                        />
                        <Typography
                          sx={{
                            fontWeight: "700",
                            textTransform: "capitalize",
                            fontFamily: "var(--font-i_r)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "0.8vw",
                            marginLeft: "10px",
                            marginTop: "-4px",
                            color: palette.primary[100],
                            [theme.breakpoints.down("sm")]: {
                              fontSize: "12px",
                            },
                          }}
                        >
                          {displayAddress()}
                          <img
                            src="https://storage.googleapis.com/3d-container/NFTDetailPageIcons/copyIcon.svg"
                            style={{ paddingLeft: "4px", cursor: "pointer" }}
                            onClick={handleCopy}
                          />
                        </Typography>
                      </Box>
                      <Box
                        width="100%"
                        justifyContent="flex-start"
                        display="flex"
                        flexWrap="wrap"
                        sx={{ cursor: "pointer", marginBottom: "6px" }}
                      >
                        <Box width={"70%"}>
                          <NftContactLinks
                            detailPage={true}
                            connections={buyNowConnections}
                            topName="BUy NOW"
                            componentKey="buyNowConnections"
                          />
                        </Box>
                        <Box
                          sx={{ backgroundColor: "#fff" }}
                          height="100%"
                          width="2px"
                        />
                        <Box width={"28%"}>
                          <NftContactLinks
                            connections={connections}
                            detailPage={true}
                            topName="LINKS"
                            componentKey="connections"
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    mt={1}
                    display="flex"
                    borderRadius="0.5vw"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      background: "#95959529",
                      cursor: "pointer",
                      height: {
                        xl: "65px",
                        lg: "45px",
                        md: "40px",
                        sm: "50px",
                        xs: "50px",
                      },
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(window.location.href);
                      enqueueSnackbar("Copied!", {
                        variant: "success",
                      });
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "var(--font-i_r)",
                        fontSize: "1vw",
                        mr: 2,
                        color: "#fff",
                        [theme.breakpoints.down("sm")]: {
                          fontSize: "14px",
                        },
                      }}
                    >
                      Copy
                    </Typography>
                    <img src="https://storage.googleapis.com/3d-container/NFTDetailPageIcons/copyIcon.svg" />
                  </Box>
                </Box>
                <Box
                  height="98%"
                  sx={{
                    width: "73%",
                    [theme.breakpoints.down("md")]: {
                      width: "100%",
                      mt: 1,
                    },
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "10px",
                      background: "#181818",
                      padding: "14px",
                      display: "flex",
                      justifyContent: "space-between",
                      border: "1px solid #2b2b2b",
                    }}
                  >
                    {tabButtons.map((btn, index) => {
                      return (
                        <Box
                          key={index + "tabButtons"}
                          onClick={() => {
                            const updated = tabButtons.map((bt) => {
                              return bt.name === btn.name
                                ? {
                                    ...bt,
                                    selected: true,
                                  }
                                : {
                                    ...bt,
                                    selected: false,
                                  };
                            });
                            setTabButtons(updated);
                          }}
                          sx={{
                            width: "45%",
                            cursor: "pointer",
                            background: btn.selected ? "#95959529" : "",
                            borderRadius: "10px",
                            display: "flex",
                            p: 1.5,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "700",
                              textTransform: "capitalize",
                              fontFamily: "var(--font-f_r)",
                              textAlign: "center",
                              fontSize: "1.1vw",
                              color: "#fff",
                              [theme.breakpoints.down("sm")]: {
                                fontSize: "14px",
                              },
                            }}
                          >
                            {btn.name}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box
                    sx={{
                      borderRadius: "10px",
                      padding: "14px",
                      mt: 2,
                      overflowY: "scroll",
                      height: { xl: "89%", lg: "83%" },
                      maxHeight: "68vh",
                      background: "#181818",
                      border: "1px solid #2b2b2b",
                    }}
                  >
                    {tabButtons.find((tab) => tab.selected)?.name ===
                    "Traits" ? (
                      <Box
                        sx={{
                          "::-webkit-scrollbar-track": {
                            backgroundColor: "#181818",
                          },
                        }}
                      >
                        {traits?.length > 0 ? (
                          <>
                            {traits
                              .filter(
                                (trait) =>
                                  !String(trait?.traitVal)?.includes("http") &&
                                  trait?.key !== "ApeCoin Staked"
                              )
                              // ?.slice(0, 12)
                              .map((trait, i) => {
                                return (
                                  <Box
                                    key={i + "trait"}
                                    display="flex"
                                    my={2}
                                    borderBottom="1px solid #95959529"
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Box width="20%">
                                      <Typography
                                        sx={{
                                          fontFamily:
                                            i > 0
                                              ? "var(--font-i_r)"
                                              : "var(--font-f_r)",
                                          fontWeight: "700",
                                          fontSize: i > 0 ? "0.6vw" : "0.8vw",
                                          textAlign: "center",
                                          color: "#C4C4C4",
                                          [theme.breakpoints.down("sm")]: {
                                            fontSize: "14px",
                                          },
                                        }}
                                      >
                                        {trait?.key}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontFamily:
                                            i > 0
                                              ? "var(--font-i_r)"
                                              : "var(--font-f_r)",
                                          fontWeight: "700",
                                          textAlign: "center",
                                          fontSize: i > 0 ? "0.6vw" : "0.8vw",
                                          color: "#fff",
                                          [theme.breakpoints.down("sm")]: {
                                            fontSize: "14px",
                                          },
                                        }}
                                      >
                                        {trait?.value}
                                      </Typography>
                                    </Box>
                                    <Typography
                                      sx={{
                                        width: "10%",
                                        fontFamily:
                                          i > 0
                                            ? "var(--font-i_r)"
                                            : "var(--font-f_r)",
                                        fontWeight: "700",
                                        textAlign: "center",
                                        fontSize: i > 0 ? "0.6vw" : "0.8vw",
                                        color: palette.primary[100],
                                        [theme.breakpoints.down("sm")]: {
                                          fontSize: "14px",
                                        },
                                      }}
                                    >
                                      {trait?.floorAskPrices[0]}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        width: "10%",
                                        fontFamily:
                                          i > 0
                                            ? "var(--font-i_r)"
                                            : "var(--font-f_r)",
                                        fontWeight: "700",
                                        fontSize: i > 0 ? "0.6vw" : "0.8vw",
                                        textAlign: "center",
                                        color: "#fff",
                                        [theme.breakpoints.down("sm")]: {
                                          fontSize: "14px",
                                        },
                                      }}
                                    >
                                      {trait?.onSaleCount}
                                    </Typography>
                                    {/* <Typography
                                  sx={{
                                    width: "10%",
                                    fontFamily: i>0?"var(--font-i_r)": "var(--font-i_r)",
                                    fontSize: i>0? "0.8vw":"1vw",
                                    color: "#fff",
                                    [theme.breakpoints.down("sm")]: {
                                      fontSize: "14px",
                                    },
                                  }}
                                >
                                  {trait?.listings}%
                                </Typography> */}
                                    <Typography
                                      sx={{
                                        width: "10%",
                                        fontFamily:
                                          i > 0
                                            ? "var(--font-i_r)"
                                            : "var(--font-f_r)",
                                        fontWeight: "700",
                                        textAlign: "center",
                                        fontSize: i > 0 ? "0.6vw" : "0.8vw",
                                        color: "#fff",
                                        [theme.breakpoints.down("sm")]: {
                                          fontSize: "14px",
                                        },
                                      }}
                                    >
                                      {trait?.tokenCount}
                                    </Typography>
                                  </Box>
                                );
                              })}
                          </>
                        ) : (
                          <Typography
                            sx={{
                              fontFamily: "var(--font-f_r)",
                              fontSize: "1vw",
                              color: "#fff",
                              mt: 2,
                              textAlign: "center",
                              [theme.breakpoints.down("sm")]: {
                                fontSize: "14px",
                              },
                            }}
                          >
                            No Data Available
                          </Typography>
                        )}
                      </Box>
                    ) : (
                      <Box sx={{ height: "70%" }}></Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {isMobile ? <MobileFooter></MobileFooter> : <FooterHome />}
    </Box>
  );
};

export default NftModelDetailsNew;
