import React, { createContext, useState, useContext, useEffect } from "react";
import instance from "../api";
import { useNavigate, useLocation } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Start with loading true
  const navigate = useNavigate();
  const location = useLocation();

  // Function to login user
  const login = async (username, password) => {
    setLoading(true);
    try {
      const response = await instance.post("/auth/signin", { username, password });
      localStorage.setItem("token", response.data.token);
      await fetchUserData(response.data.token);
      return response;

    } catch (error) {
      console.error(error);
      setLoading(false);
      throw error;

    }
  };

  // Function to fetch user data with token
  const fetchUserData = async () => {
    try {
      const response = await instance.get("/auth/profile");
      const { data } = response;
      localStorage.setItem("user", JSON.stringify(data));
      setUser(data);
    } catch (error) {
      console.error(error);
      logout(); // If the token is invalid, log out
    } finally {
      setLoading(false);
    }
  };

  // Function to logout user
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setUser(null);
    navigate("/login"); // Redirect to login page after logout
  };

  // Verify token and fetch user data when the component mounts
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetchUserData();
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
