import styled from "@emotion/styled";
import {
  Box,
  LinearProgress,
  Typography,
  linearProgressClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "10px",

  width: "60%",
  borderRadius: 3,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 3,
    backgroundColor: "#FFB800",
  },
}));

const GiftBox = () => {
  const unity = useUnityContext({
    // dataUrl: "/webgl.data",
    // codeUrl: "/build.wasm",
    // loaderUrl: "/Box.loader.js",
    // frameworkUrl: "/build.framework.js",
    loaderUrl:
      "https://storage.googleapis.com/3d-container/giftBox/LootBox.loader.js",
    frameworkUrl:
      "https://storage.googleapis.com/3d-container/giftBox/build.framework.js",
    dataUrl: "https://storage.googleapis.com/3d-container/giftBox/webgl.data",
    codeUrl: "https://storage.googleapis.com/3d-container/giftBox/build.wasm",
  });
  const [loadingName, setLoadingName] = useState("Loading Box");

  return (
    <Box>
      {!unity?.isLoaded && (
        <Box
          sx={{
            width: "100vw",
            position: "absolute",
            display: unity?.isLoaded ? "none" : "flex",
            height: "100vh",
            alignItems: "center",
            // margin: "auto",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {/* <Typography
            sx={{
              fontWeight: "800",
              fontFamily: "var(--font-f_r)",
              fontSize: "26px",
              mb: 2,
              width: "100%",
              textAlign: "center",
              textTransform: "capitalize",
              color: "#C4C4C4",
            }}
          >
            {loadingName}
          </Typography> */}
          <BorderLinearProgress
            variant="determinate"
            value={unity?.loadingProgression * 100}
          />
        </Box>
      )}
      <Unity
        style={{ width: "100%", height: "99vh" }}
        unityProvider={unity?.unityProvider}
      />
    </Box>
  );
};
export default GiftBox;
