import Layout from "./Layout";
import WithdrawAction from "./withdrawAction/Index";

const Withdraw = ({ show, onClose, title }) => {
  const data = [];
  return (
    <Layout show={show} onClose={onClose} title={title}>
      <WithdrawAction />
    </Layout>
  );
};
export default Withdraw;
