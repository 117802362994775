import { useTheme } from "@emotion/react";
import { Box, Button, Slider, Stack, Typography } from "@mui/material";
import * as React from "react";
import Header from "../Header";
import FooterHome from "../footer/FooterHome";
import { htmlToImageConvert } from "../../utils";
import baseImg from "../../assets/A1.jpg";
import html2canvas from "html2canvas";

function ImageTextureUploader() {
  const { palette, ...theme } = useTheme();
  const elementRef = React.useRef(null);
  const [selectedImage, setSelectedImage] = React.useState(null);

  const convertCanvasToBase64 = () => {
    const div = elementRef.current;

    // html2canvas(div)
    //   .then((canvas) => {
    //     const dataURL = canvas.toDataURL("image/png"); // Convert the canvas to Base64
    //     console.log(dataURL);
    //   })
    //   .catch((error) => {
    //     console.error("Error converting div to base64:", error);
    //   });
  };

  // Handle the image file selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
    }
  };
  const [sliderValue, setSliderValue] = React.useState(0);
  const [scale, setScale] = React.useState(60);
  const [top, setTop] = React.useState(0);
  const [left, setLeft] = React.useState(0);

  // Calculate rotation based on slider value
  const rotationDegree = (sliderValue / 100) * 360;
  const topValue = top + 185;
  const leftValue = left + 180;
  return (
    <Box
      sx={{
        backgroundPosition: "bottom",
        backgroundSize: "cover",
      }}
    >
      <Header hamburger={true} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: "20vh",
          height: "100vh",
        }}
      >
        {" "}
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{
            position: "absolute",
            top: "100px",
          }}
        />
        <div
          ref={elementRef}
          id="videoAnimation"
          style={{ position: "absolute", width: "500px", height: "500px" }}
        >
          <img style={{ width: "500px", height: "500px" }} src={baseImg} />
          {selectedImage && (
            <img
              style={{
                position: "absolute",
                top: `${topValue}px`,
                left: `${leftValue}px`,
                width: `${scale}px`,
                height: `${scale}px`,
                transform: `rotate(${rotationDegree}deg)`,
                transition: "transform 0.2s ease-out", // Smooth rotation
              }}
              src={selectedImage}
            />
          )}
        </div>
        <div style={{ position: "absolute", bottom: "80px" }}>
          <Stack direction="row" spacing={2}>
            <Stack sx={{ alignItems: "center", my: 1 }}>
              <Typography sx={{ color: "#fff" }}>Vertical Position</Typography>
              <Slider
                aria-label="Volume"
                value={top}
                onChange={(e) => setTop(e.target.value)}
              />
            </Stack>
            <Stack sx={{ alignItems: "center", my: 1 }}>
              <Typography sx={{ color: "#fff" }}>
                Horizontal Position
              </Typography>
              <Slider
                aria-label="Volume"
                value={left}
                sx={{ width: "150px" }}
                onChange={(e) => setLeft(e.target.value)}
              />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Stack sx={{ alignItems: "center", my: 1 }}>
              <Typography sx={{ color: "#fff" }}>Change Orientation</Typography>
              <Slider
                aria-label="Volume"
                value={sliderValue}
                onChange={(e) => setSliderValue(e.target.value)}
              />
            </Stack>
            <Stack sx={{ alignItems: "center", my: 1 }}>
              <Typography sx={{ color: "#fff" }}>Scale</Typography>
              <Slider
                aria-label="Volume"
                value={scale}
                sx={{ width: "150px" }}
                onChange={(e) => setScale(e.target.value)}
              />
            </Stack>
          </Stack>
          <Button
            onClick={() => {
              convertCanvasToBase64();
              htmlToImageConvert("newww.jpg", elementRef);
            }}
          >
            Export
          </Button>
        </div>
      </Box>
      <FooterHome />
    </Box>
  );
}

export default ImageTextureUploader;
