import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.secondary[100],
        height: "1px",
        overflow: 'visible',
        marginTop: "4px"
    },
    [`& .${linearProgressClasses.bar}`]: {
        height: 5,
        borderRadius: 5,
        top: "-2px",
        backgroundColor: theme.palette.primary[500],
    },
}));


export default function ProgressBar({ step, stepTitles }) {
    const progress = step / stepTitles * 28;
    return (
        <Box sx={{ flexGrow: 1, width: "100%", height: "10px", overflow: "hidden" }}>
            <BorderLinearProgress variant="determinate" value={75 + progress} />
        </Box>
    );
}
