import { useTheme } from "@emotion/react";
import { Typography, Box, Grid, useMediaQuery } from "@mui/material";
import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import connectLogo from "../assets/images/arm.png";
import CustomButton from "./Button";
import CustomDialog from "./Dialog";

const WalletDialog = ({ walletPopup, setWalletPopup }) => {
  const { palette, ...theme } = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <CustomDialog
      onClose={() => setWalletPopup(false)}
      open={walletPopup}
      width={isMobile ? "80vw" : "50vw"}
      body={
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12} md={6}>
            <Box
              justifyItems="end"
              justifyContent="right"
              alignItems="end"
              width="100%"
              sx={{
                cursor: "pointer",
                position: "absolute",
                right: "16px",
              }}
              display="flex"
              alignSelf="self-end"
              marginTop="10px"
            >
              <RxCross2
                onClick={() => {
                  setWalletPopup(false);
                }}
                color={"#fff"}
                size={30}
              />
            </Box>
            <Box sx={{ paddingInline: "1vw" }}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontFamily: "var(--font-f_r)",
                  fontSize: "25px",
                  marginBlock: "1vw",
                  textAlign: "start",
                  color: "#ffff",
                }}
              >
                Connect wallet
              </Typography>
              <WalletButton
                title="Metamask"
                img="https://ishrostorage.blob.core.windows.net/container-3d/metamask.png"
              />
              <WalletButton
                title="coinbase wallet"
                img="https://ishrostorage.blob.core.windows.net/container-3d/coinbase.png"
              />
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: {xl:"absolute",lg:"absolute",md:"absolute",xs:"static"},
                  bottom: "1vh",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "var(--font-i_r)",
                    color: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    fontSize: {
                      xl: "1em",
                      lg: "0.7em",
                      md: "0.4em",
                      xs: "0.8em",
                      sm: "0.8em",
                    },
                  }}
                >
                  Support
                </Typography>
                <div
                  style={{
                    marginLeft: "1.5vh",
                    height: "18px",
                    width: "1px",
                    background: "#C4C4C4",
                    // border: "1px solid #fff",
                  }}
                />

                <img
                  style={{
                    height: "auto",
                    width: "100%",
                    maxWidth: "100px",
                    marginLeft: "1vh",
                  }}
                  variant="square"
                  src="https://storage.googleapis.com/3d-container/delegate.png"
                  alt="delegate"
                />
                <img
                  style={{
                    height: "auto",
                    width: "100%",
                    maxWidth: "60px",
                    marginLeft: "1vh",
                  }}
                  variant="square"
                  src="https://storage.googleapis.com/3d-container/arm.png"
                  alt="arm"
                />
                <img
                  style={{
                    height: "auto",
                    width: "100%",
                    maxWidth: "100px",
                    marginLeft: "1vh",
                  }}
                  variant="square"
                  src="https://storage.googleapis.com/3d-container/Benddao%20(2).png"
                  alt="benddao"
                />
                <img
                  style={{
                    height: "auto",
                    width: "100%",
                    maxWidth: "100px",
                    marginLeft: "1vh",
                  }}
                  variant="square"
                  src="https://storage.googleapis.com/3d-container/Parallel.png"
                  alt="benddao"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: "#242424",
                borderRadius: "0.8vw",
                padding: "0.5vw",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src="https://ishrostorage.blob.core.windows.net/container-3d/wallet.png" />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontFamily: "var(--font-f_r)",
                    fontSize: "25px",
                    textAlign: "center",
                    marginTop: "20px",
                    color: "#fff",
                  }}
                >
                  first time using a wallet?
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "300",
                    fontFamily: "var(--font-i_r)",
                    fontSize: "20px",
                    marginTop: "10px",
                    textAlign: "center",
                    color: palette.font.default,
                  }}
                >
                  A wallet opens up a new digital world. get a <br /> wallet to
                  experience all the great features and benefits.
                </Typography>
              </Box>
              <CustomButton
                width="240px"
                height="auto"
                sx={{
                  marginBlock: "1.5vw",
                  background:
                    "linear-gradient(95deg, #B19513 -2.12%, #BF5C00 116.91%)",
                }}
                title="get a wallet"
                logoPosition="left"
                logo={
                  <img
                    style={{
                      width: "20%",
                    }}
                    src={connectLogo}
                  />
                }
              />
            </Box>
          </Grid>
        </Grid>
      }
    />
  );
};

const WalletButton = ({ title, img }) => {
  return (
    <Box
      sx={{
        marginBlock: "1vw",
        borderRadius: "0.5vw",
        border: "1px solid #FFFFFF66",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingBlock: "0.8vw",
      }}
    >
      <Box sx={{ width: "90%", display: "flex", alignItems: "center" }}>
        <img style={{ marginInline: "1vw" }} src={img} />
        <Typography
          sx={{
            fontWeight: "600",
            fontFamily: "var(--font-f_r)",
            fontSize: "18px",
            textAlign: "center",
            color: "#ffff",
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ width: "10%", display: "flex", alignItems: "center" }}>
        <FiChevronRight size={28} color="#fff" />
      </Box>
    </Box>
  );
};

export default WalletDialog;
