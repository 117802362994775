import axios from "./axios";
export const clientService = async (showLoader, hideLoader, pageSize = 7) => {
  try {
    const response = await axios.get(
      `dashboard/companies?pageSize=${pageSize}&page=1`
    );
    if (response.status === 200) {
      return response.data.companies;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};
export const clientProjects = async (slug) => {
  try {
    const response = await axios.get(`companies/slug/${slug}`);
    if (response.status === 404) {
      window.location.href = window.location.origin + "/404";
    }
    if (response.status === 200) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};
export const getProjectChildrenBySlug = async (slug) => {
  try {
    const response = await axios.get(`projects/children/${slug}`);
    if (response.status === 200) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};
export const clientProjectsDetails = async (slugan) => {
  try {
    const response = await axios.get(`projects/slugan/${slugan}`);
    if (response.status === 404) {
      window.location.href = window.location.origin + "/404";
    }
    if (response.status === 200) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};
export const clientCollectionData = async (slugan) => {
  try {
    const response = await axios.get(`projects/collectionsData/${slugan}`);
    if (response.status === 200) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const getTraitsById = async (address, nftId) => {
  const options = {
    method: "GET",
    headers: {
      accept: "*/*",
      "x-api-key": "31505e92-0c8f-5a0d-b902-bff81ed7e665",
    },
  };

  const response = await fetch(
    `https://api.reservoir.tools/collections/${address}/attributes/explore/v5?tokenId=${nftId}`,
    options
  );
  return await response.json();
};
export const getNftById = async (slugan, id) => {
  try {
    const response = await axios.get(`opensea/token/${id}/${slugan}`);
    if (response.status === 200) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};
