import React, { useState } from "react";
import {
  Typography,
  Box,
  Grid,
  useTheme,
  TextField,
  useMediaQuery,
} from "@mui/material";
import CustomDialog from "./Dialog";
import ConnectWallet from "../components/buttons/ConnectWallet";

const LoginDialog = ({ loginPopup, setLoginPopup, mode, setMode }) => {
  const { palette, ...theme } = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const [formData, setFormData] = useState({
    name: "",
    password: "",
    email: "",
    discord: "",
    twitter: "",
  });
  const [errors, setErrors] = useState({});

  return (
    <CustomDialog
      onClose={() => setLoginPopup(false)}
      open={loginPopup}
      width="100%"
      body={
        <Grid container spacing={isMediumScreen ? 0 : 0} padding={2}>
          <Grid item xs={12} md={6} xl={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontFamily: "var(--font-f_r)",
                    fontSize: "32px",
                    marginBlock: "1vw",
                    textAlign: "start",
                    color: "#ffff",
                  }}
                >
                  {mode === "login" ? "Sign In Now" : "Sign up"}
                </Typography>
                {mode === "signup" && (
                  <>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontFamily: "var(--font-i_r)",
                        fontSize: "14px",
                        color: "#ffff",
                        alignSelf: "flex-start",
                        marginBottom: "5px",
                      }}
                    >
                      Name
                    </Typography>
                    <TextField
                      id="name"
                      variant="outlined"
                      fullWidth
                      sx={{
                        color: "#fff",
                        marginBlock: "10px",
                        fontFamily: "var(--font-i_r)",

                        "& .MuiInputBase-input": { color: "#fff" },
                        "& .MuiInputLabel-root": { color: "#fff" },
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          { borderColor: palette.button.default },
                      }}
                      value={formData.name}
                      helperText={errors.name}
                      error={Boolean(errors.name)}
                      onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                    />
                  </>
                )}
                <>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontFamily: "var(--font-i_r)",
                      fontSize: "14px",
                      color: "#ffff",
                      alignSelf: "flex-start",
                      marginBottom: "5px",
                    }}
                  >
                    Email Id
                  </Typography>
                  <TextField
                    id="email"
                    variant="outlined"
                    fullWidth
                    sx={{
                      color: "#fff",
                      marginBlock: "10px",
                      fontFamily: "var(--font-i_r)",

                      "& .MuiInputBase-input": { color: "#fff" },
                      "& .MuiInputLabel-root": { color: "#fff" },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        { borderColor: palette.button.default },
                    }}
                    value={formData.email}
                    helperText={errors.email}
                    error={Boolean(errors.email)}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </>
                <>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontFamily: "var(--font-i_r)",
                      fontSize: "14px",
                      color: "#ffff",
                      alignSelf: "flex-start",
                      marginBottom: "5px",
                    }}
                  >
                    Password
                  </Typography>
                  <TextField
                    id="password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    sx={{
                      color: "#fff",
                      marginBlock: "10px",
                      fontFamily: "var(--font-i_r)",

                      "& .MuiInputBase-input": { color: "#fff" },
                      "& .MuiInputLabel-root": { color: "#fff" },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        { borderColor: palette.button.default },
                    }}
                    value={formData.password}
                    helperText={errors.password}
                    error={Boolean(errors.password)}
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                  />
                </>
                {mode === "signup" && (
                  <>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontFamily: "var(--font-i_r)",
                        fontSize: "14px",
                        color: "#ffff",
                        alignSelf: "flex-start",
                        marginBottom: "5px",
                      }}
                    >
                      Discord ID
                    </Typography>
                    <TextField
                      id="discord"
                      variant="outlined"
                      fullWidth
                      sx={{
                        color: "#fff",
                        marginBlock: "10px",
                        fontFamily: "var(--font-i_r)",

                        "& .MuiInputBase-input": { color: "#fff" },
                        "& .MuiInputLabel-root": { color: "#fff" },
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          { borderColor: palette.button.default },
                      }}
                      value={formData.discord}
                      helperText={errors.discord}
                      error={Boolean(errors.discord)}
                      onChange={(e) =>
                        setFormData({ ...formData, discord: e.target.value })
                      }
                    />
                  </>
                )}
                {mode === "signup" && (
                  <>
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontFamily: "var(--font-i_r)",
                        fontSize: "14px",
                        color: "#ffff",
                        alignSelf: "flex-start",
                        marginBottom: "5px",
                      }}
                    >
                      Twitter ID
                    </Typography>
                    <TextField
                      id="twitter"
                      variant="outlined"
                      fullWidth
                      sx={{
                        color: "#fff",
                        marginBlock: "10px",
                        fontFamily: "var(--font-i_r)",

                        "& .MuiInputBase-input": { color: "#fff" },
                        "& .MuiInputLabel-root": { color: "#fff" },
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          { borderColor: palette.button.default },
                      }}
                      value={formData.twitter}
                      helperText={errors.twitter}
                      error={Boolean(errors.twitter)}
                      onChange={(e) =>
                        setFormData({ ...formData, twitter: e.target.value })
                      }
                    />
                  </>
                )}
                <ConnectWallet
                  label={mode === "login" ? "Sign in" : "Sign up"}
                  isHeader
                  sx={{
                    marginBlock: "1.5vw",
                  }}
                ></ConnectWallet>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontFamily: "var(--font-i_r)",
                      fontSize: "14px",
                      textAlign: "start",
                      color: "#4A4A4A",
                    }}
                  >
                    {mode === "login"
                      ? "Don’t have an account?"
                      : "Already have an account?"}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontFamily: "var(--font-i_r)",
                      fontSize: "18px",
                      textAlign: "start",
                      color: "#C4C4C4",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                    onClick={() => {
                      mode === "login" ? setMode("signup") : setMode("login");
                    }}
                  >
                    {mode === "login" ? "Sign Up" : "Sign in"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          {!isMediumScreen && (
            <Grid item xs={12} md={6} xl={6}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  width="100%"
                  src="https://ishrostorage.blob.core.windows.net/container-3d/login.png"
                />
              </Box>
            </Grid>
          )}
        </Grid>
      }
    />
  );
};

export default LoginDialog;
