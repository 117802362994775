import { createSlice } from "@reduxjs/toolkit";
import axios from "../services/axios";
import { saveUserData, saveToken } from "../services/authService";
import { getUserData, destroy } from "../services/authService";

const initialState = {
  loading: true,
  error: false,
  user: [],
  userFormData: "",
};

function intersect_arrays(a, b) {
  var sorted_a = a.concat().sort();
  var sorted_b = b.concat().sort();
  var common = [];
  var a_i = 0;
  var b_i = 0;

  while (a_i < a.length && b_i < b.length) {
    if (sorted_a[a_i] === sorted_b[b_i]) {
      common.push(sorted_a[a_i]);
      a_i++;
      b_i++;
    } else if (sorted_a[a_i] < sorted_b[b_i]) {
      a_i++;
    } else {
      b_i++;
    }
  }
  return common;
}

const noCompliance = [
  "NumberOfEmployees",
  "InsuranceDetails",
  "ShareCapital",
  "ShareCapitalCurrency",
  "ActualTurnover",
  "ExpectedTurnover",
  "ContactVOIPType",
  "ContactVoIPID",
  "CustomerReference1Name",
  "CustomerReference1Address",
  "CustomerReference1MajorProductsL",
  "CustomerReference2Name",
  "CustomerReference2Address",
  "CustomerReference2MajorProductsL",
];

const apCompliance = [
  "VendorPhone",
  "VendorWebsite",
  "BankName",
  "AccountNumber",
  "BeneficiaryName",
  "IBAN",
  "SWIFT_x002d_BIC",
  "BranchName",
  "BranchAddress",
  "BranchAddress2",
  "BranchCity",
  "BranchPostalCode",
  "BranchRegion",
  "BranchCountry",
  "CorrespondentBank",
  "CorrespondentBankSWIFT",
  "PaymentCurrency",
  "DocumentDeliveryMethod",
  "PreferredMethodOfPayment",
  "RetainagePercentage",
  "ContactPersonFirstName",
  "ContactPersonLastName",
  "ContactAddress",
  "ContactAddress2",
  "ContactCity",
  "ContactPostalCode",
  "ContactRegion",
  "ContactCountry",
  "ContactPhone",
  "ContactEmail",
];

const normal = [
  "EntityName",
  "LegalStatus",
  "CountryofIncorporation",
  "DateOfIncorporation",
  "BusinessRegistrationNumber",
  "BusinessRegistrationExpiryDate",
  "NatureOfBusiness",
  "VendorAddress",
  "VendorAddress2",
  "VendorCity",
  "VendorPostalCode",
  "VendorRegion",
  "VendorCountry",
  "ParentCompany",
  "UltimateOwner",
  "Nationality",
  "NationalIDNumber",
  "TaxRegistrationNumber",
];

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setUser: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.errormsg = "";
      state.userFormData = payload.data;
    },
    setUserFormData: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.errormsg = "";
      state.userFormData = payload.data;
      state.user = payload.user;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
  },
});

export const { setLoading, setUser, setError, setUserFormData } =
  userSlice.actions;

const { reducer } = userSlice;
export default reducer;

export const userRegister = (body) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      for (var key in body) {
        if (key === "VendorEmail") {
          body[key] = JSON.parse(getUserData()).email;
        } else {
          body[key] = body[key]?.label ? body[key].value : body[key];
        }
      }
      let payload = body;

      const files = {
        ValidTradeLicenseLink: body.ValidTradeLicenseLink,
        CommercialRegistryLink: body.CommercialRegistryLink,
        TaxRegistrationCertificateLink: body.TaxRegistrationCertificateLink,
        BankReferenceLetterLink: body.BankReferenceLetterLink,
        UltimateOwnerIDLink: body.UltimateOwnerIDLink,
      };

      const allFiles = body.FinancialStatementLink
        ? {
            ...files,
            FinancialStatementLink: body.FinancialStatementLink,
          }
        : files;

      const promises = Object.values(allFiles).map((link) => {
        const formData = new FormData();
        formData.append("files", link);
        return axios.post("/upload-file", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      });

      Promise.all(promises)
        .then((responses) => {
          responses.forEach((response, index) => {
            const key = Object.keys(allFiles)[index];
            payload = {
              ...payload,
              [key]: {
                __metadata: {
                  type: "SP.FieldUrlValue",
                },
                Url: response.data.data[0].public_url,
                Description: response.data.data[0].public_url,
              },
            };
          });

          axios
            .post("profile/add", JSON.stringify(payload), {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json; charset=utf-8",
              },
            })
            .then((response) => {
              if (response.status === 201) {
                dispatch(setUser(response.data.data));
                resolve(response);
              }
            })
            .catch((error) => {
              if (!error.response.data.success) {
                dispatch(setError());
                reject(error);
              }
            });
        })
        .catch((error) => {
          if (!error.response.data.success) {
            dispatch(setError());
            reject(error);
          }
        });
    });
};

export const userUpdate = (body, lastData) => {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      let changeValueKeys = [];
      for (var ky in body) {
        var Array = Object.entries(lastData);
        let obj = Array.filter((obj) => obj[0] === ky);
        let lastKeyValue =
          obj[0] && (obj[0][1]?.value ? obj[0][1].value : obj[0][1]);
        let currentKeyValue = body[ky]?.value ? body[ky].value : body[ky];
        if (
          ky === "DateOfIncorporation" ||
          ky === "BusinessRegistrationExpiryDate"
        ) {
          lastKeyValue = new Date(lastKeyValue).toLocaleDateString("en-US");
          currentKeyValue = new Date(currentKeyValue).toLocaleDateString(
            "en-US"
          );
        }
        if (
          ky !== "ValidTradeLicenseLink" &&
          ky !== "CommercialRegistryLink" &&
          ky !== "TaxRegistrationCertificateLink" &&
          ky !== "FinancialStatementLink" &&
          ky !== "BankReferenceLetterLink" &&
          ky !== "UltimateOwnerIDLink"
        ) {
          if (currentKeyValue !== lastKeyValue) {
            changeValueKeys.push(ky);
          }
        }
        if (ky === "isRetainageAllowed") {
          if (!body[ky]) {
            body["RetainagePercentage"] = 0;
          }
        } else {
          if (body[ky]) {
            body[ky] = body[ky]?.label ? body[ky].value : body[ky];
          }
        }
      }
      let totalArray = [];
      let noCompArr = {
        name: "No Compliance",
        value: intersect_arrays(changeValueKeys, noCompliance).length,
      };
      totalArray.push(noCompArr);

      let apCompArr = {
        name: "AP Compliance",
        value: intersect_arrays(changeValueKeys, apCompliance).length,
      };
      totalArray.push(apCompArr);
      let normalArr = {
        name: "Normal",
        value: intersect_arrays(changeValueKeys, normal).length,
      };

      totalArray.push(normalArr);
      const mostKeys = totalArray.reduce((prev, current) => {
        return prev.value > current.value ? prev : current;
      });

      body["VendorSubmissionType"] =
        mostKeys.value === 0 ? "Normal" : mostKeys.name;

      let payload = body;
      const files = {
        ValidTradeLicenseLink: body.ValidTradeLicenseLink,
        CommercialRegistryLink: body.CommercialRegistryLink,
        TaxRegistrationCertificateLink: body.TaxRegistrationCertificateLink,
        BankReferenceLetterLink: body.BankReferenceLetterLink,
        UltimateOwnerIDLink: body.UltimateOwnerIDLink,
      };

      const allFiles = body.FinancialStatementLink
        ? {
            ...files,
            FinancialStatementLink: body.FinancialStatementLink,
          }
        : files;

      // const filesToUpload = Object.values(allFiles).filter(
      //   (value) => !value?.__metadata
      // );

      const filesToUpload = [];
      for (var key in allFiles) {
        if (allFiles[key]) {
          if (!allFiles[key]?.__metadata) {
            allFiles[key].key = key;
            filesToUpload.push(allFiles[key]);
          }
        }
      }

      if (filesToUpload.length) {
        const promises = filesToUpload.map((link) => {
          const formData = new FormData();
          if (link) {
            formData.append("files", link);
            return axios.post("/upload-file", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          }
        });
        Promise.all(promises)
          .then((responses) => {
            responses.forEach((response, index) => {
              const key = filesToUpload[index].key;
              payload = {
                ...payload,
                [key]: {
                  __metadata: {
                    type: "SP.FieldUrlValue",
                  },
                  Url: response.data.data[0].public_url,
                  Description: response.data.data[0].public_url,
                },
              };
            });
            axios
              .post("profile/update", JSON.stringify(payload), {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json; charset=utf-8",
                },
              })
              .then((response) => {
                if (response.status === 201) {
                  dispatch(setUser(response.data.data));
                  resolve(response);
                }
              })
              .catch((error) => {
                if (!error.response.data.success) {
                  dispatch(setError());
                  reject(error);
                }
              });
          })
          .catch((error) => {
            if (!error.response.data.success) {
              dispatch(setError());
              reject(error);
            }
          });
      } else {
        axios
          .post("profile/update", JSON.stringify(payload), {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json; charset=utf-8",
            },
          })
          .then((response) => {
            if (response.status === 201) {
              dispatch(setUser(response.data.data));
              resolve(response);
            }
          })
          .catch((error) => {
            if (!error.response.data.success) {
              dispatch(setError());
              reject(error);
            }
          });
      }
    });
};

export const getProfile = (user_id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get("profile/" + user_id)
        .then((response) => {
          if (response.status === 200) {
            saveUserData(response.data.data.user);
            dispatch(setUserFormData(response.data.data));
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const register = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("register", JSON.stringify(body))
        .then((response) => {
          if (response.status === 201) {
            saveUserData(response.data.data.user);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const login = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("login", JSON.stringify(body))
        .then((response) => {
          if (response.status === 200) {
            saveUserData(response.data.data.user);
            saveToken(response.data.token);
            dispatch(getProfile(response.data.data.user.user_id));
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const loginWithMS = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("login-with-ms", JSON.stringify(body))
        .then((response) => {
          if (response.status === 200) {
            saveUserData(response.data.data.user);
            saveToken(response.data.token);
            dispatch(setUserFormData(response.data.data));
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const loginWithManager = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("login-with-manager", JSON.stringify(body))
        .then((response) => {
          if (response.status === 200) {
            saveUserData(response.data.data.user);
            saveToken(response.data.token);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const accountActivationLink = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("resend-activation-link", JSON.stringify(body))
        .then((response) => {
          if (response?.status === 201) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const forgotPassword = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("forgot-password", JSON.stringify(body))
        .then((response) => {
          if (response?.status === 200) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const resetPassword = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("reset-password", JSON.stringify(body))
        .then((response) => {
          if (response?.status === 200) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const changePassword = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("change-password", JSON.stringify(body))
        .then((response) => {
          if (response?.status === 200) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const sendOtp = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("send-otp", JSON.stringify(body))
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const verifyOtp = (body) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post("verify-otp", JSON.stringify(body))
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          if (!error?.response?.data?.success) {
            dispatch(setError());
          }
        });
    });
  };
};

export const logout = () => {
  destroy();
  window.location.reload(true);
};

export const managerLogout = () => {
  destroy();
  window.location.href = "/admin-portal";
};
