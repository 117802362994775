import axios from "./axios";

export const getNftByAssetId = async (chain, identifier, id) => {
  const options = {
    method: "GET",
    headers: {
      accept: "application/json",
      "X-API-KEY": "a5d5a69ec5aa4d78a4db72db0859bab7",
    },
  };

  const result = await fetch(
    `https://api.opensea.io/v2/chain/${chain}/contract/${identifier}/nfts/${id}`,
    options
  );
  if (result?.status === 200) {
    const response = await result.json();
    return response;
  }
  return null;
};

export const getNftByAssetIdByMagicEden = async (identifier, id) => {
  try {
    const options = {
      method: "GET",
      headers: { accept: "*/*" },
    };

    const response = await fetch(
      `https://api-mainnet.magiceden.dev/v3/rtp/ethereum/tokens/v6?collection=${identifier}&tokenName=${id}&sortBy=floorAskPrice&limit=20&includeTopBid=false&excludeEOA=false&includeAttributes=false&includeQuantity=false&includeDynamicPricing=false&includeLastSale=false&normalizeRoyalties=false`,
      options
    );
    return await response.json();
  } catch (e) {
    console.log(e);
  }
};
export const getCryptoPrices = async (coin) => {
  try {
    const response = await axios.get(
      `https://api.binance.com/api/v3/ticker/price?symbol=${coin}`
    );
    return response?.data;
  } catch (error) {
    console.error(error);
  }
};

export const getCollectionDetails = async (
  collection_slug,
  showLoader,
  hideLoader
) => {
  showLoader();

  try {
    const response = await axios.get(`opensea/collection/${collection_slug}`);
    console.log(response.data, 'reponse from collection data');

    if (response.status === 200) {
      hideLoader();
      return response.data;
    } else {
      hideLoader();
      return response.data;
    }
  } catch (error) {
    hideLoader();
    return error;
  }
};

export const getNFTsByCollection = async (
  collection_slug,
  showLoader,
  hideLoader
) => {
  const options = {
    method: "GET",
    headers: {
      accept: "application/json",
      "X-API-KEY": "a5d5a69ec5aa4d78a4db72db0859bab7",
    },
  };
  try {
    showLoader();
    const result = await fetch(
      `https://api.opensea.io/v2/collection/${collection_slug}/nfts?limit=15`,
      options
    );
    if (result?.status === 200) {
      const response = await result.json();
      hideLoader();
      return response;
    }
    hideLoader();
    return null;
  } catch (e) {
    hideLoader();
    return null;
  }
};
