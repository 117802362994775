import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect } from "react";

const Layout = ({
  show,
  onClose,
  title,
  children,
  alignTitle = "flex-start",
  dialogSize = "sm",
  closeButton = true,
  layoutHeight = "auto",
}) => {
  const [open, setOpen] = useState(show);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={dialogSize}
      fullWidth
      PaperProps={{
        style: {
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "auto",
          minHeight: { layoutHeight },
          backgroundColor: "#000",
          border: "1px solid #FFB800",
          borderRadius: "1em",
        },
      }}
    >
      <DialogTitle
        sx={{
          flexDirection: "row",
          alignSelf: alignTitle,
          color: "#FFB800",
          fontWeight: "700",
          fontFamily: "var(--font-f_r)",
          fontSize: "35px",
        }}
      >
        <Typography
          paddingLeft={3}
          sx={{
            color: "#FFB800",
            fontWeight: "700",
            fontFamily: "var(--font-f_r)",
            fontSize: "35px",
          }}
        >
          {title}
        </Typography>
        {closeButton && (
          <CloseIcon
            onClick={handleClose}
            fontSize="medium"
            style={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#FFF",
              cursor: "pointer",
            }}
          />
        )}
      </DialogTitle>
      <DialogContent sx={{ width: "100%" }}>{children}</DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default Layout;
