import { Box, Typography } from "@mui/material";
import { backSvg } from "../assets/images";
import { useTheme } from "@emotion/react";

const NftContactLinks = ({
  detailPage = false,
  topName,
  connections,
  disableName = false,
  center = true,
  componentKey = "desktop",
}) => {
  const { palette, ...theme } = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: center ? "center" : "flex-start",
        margin: "0",
        padding: "0",
      }}
    >
      {!disableName && (
        <Typography
          sx={{
            fontWeight: "700",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "capitalize",
            fontFamily: "var(--font-f_r)",
            fontSize: detailPage ? "0.8vw" : "1vw",
            marginBottom: { xl: "20px", lg: "12px" },
            [theme.breakpoints.down("md")]: {
              fontSize: "16px",
            },
            [theme.breakpoints.down("sm")]: {
              fontSize: "10px",
            },

            color: palette.grey[100],
          }}
        >
          {topName}
        </Typography>
      )}
      <Box
        display="flex"
        sx={{
          display: "flex",
          justifyItems: "center",
          justifyContent: center ? "space-between" : "flex-start",
        }}
      >
        {connections.map((project) => (
          <Box
            key={project.id + componentKey}
            onClick={project.onClick}
            sx={{
              maxHeight: "24px",
              marginInline: { xl: "19px", lg: "10px", md: "8px" },
              cursor: "pointer",
            }}
          >
            {project.icon}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default NftContactLinks;
