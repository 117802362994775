import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  library3d: [],
  library2d: [],
};

const librarySlice = createSlice({
  name: "librarySlice",
  initialState,
  reducers: {
    addLibrary3d: (state, { payload }) => {
      state.library3d = [...state.library3d, payload];
    },
    addLibrary2d: (state, { payload }) => {
      state.library2d = payload;
    },
  },
});

export const { addLibrary3d, addLibrary2d } = librarySlice.actions;

const { reducer } = librarySlice;
export default reducer;

export const newLibrary3d = (body) => {
  return (dispatch) => {
    dispatch(addLibrary3d(body));
  };
};

export const newLibrary2d = (body) => {
  return (dispatch) => {
    dispatch(addLibrary2d(body));
  };
};
