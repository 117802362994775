import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeNft: {},
};

const nftSlice = createSlice({
  name: "nftSlice",
  initialState,
  reducers: {
    setActiveNft: (state, { payload }) => {
      state.activeNft = payload;
    },
  },
});

export const { setActiveNft } = nftSlice.actions;

const { reducer } = nftSlice;
export default reducer;

export const newActiveNft = (body) => {
  return (dispatch) => {
    dispatch(setActiveNft(body));
  };
};
