import React, { useState } from "react";
import { Button, Box, styled } from "@mui/material";
import WithdrawDialog from "./WithdrawDialog";
import PaymentTransaction from "./PaymentTransaction";

const StyledButton = styled(Button)(({ theme, active }) => ({
  color: active ? "#000" : "#fff",
  backgroundColor: active ? "#FFB800" : "transparent",
  width: "10vw",
  fontWeight: active ? "bold" : "normal",
  textTransform: "none",
  "&:hover": {
    color: "#FFB800",
  },
}));

const WithdrawActions = () => {
  const [activeTab, setActiveTab] = useState("Withdraw");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-around"
        width="100%"
        marginBottom={2}
        sx={{
          border: "1px solid #FFB800",
          padding: 1,
          borderRadius: "10px",
        }}
      >
        <StyledButton
          active={activeTab === "Deposit"}
          onClick={() => handleTabChange("Deposit")}
        >
          Deposit
        </StyledButton>
        <StyledButton
          active={activeTab === "Withdraw"}
          onClick={() => handleTabChange("Withdraw")}
        >
          Withdraw
        </StyledButton>

        <StyledButton
          active={activeTab === "History"}
          onClick={() => handleTabChange("History")}
        >
          History
        </StyledButton>
      </Box>
      <Box>
        {activeTab === "Withdraw" && <WithdrawDialog />}
        {activeTab === "Deposit" && <WithdrawDialog flag={true} />}
        {activeTab === "History" && <PaymentTransaction />}
      </Box>
    </>
  );
};

export default WithdrawActions;
