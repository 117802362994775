import React from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";

const Root = styled("div")(({ height, theme, width, full, logo }) => {
  return {
    alignItems: "center",
    paddingInline: "20px",
    [theme.breakpoints.down("sm")]: {
      paddingInline: "5px",
    },
    "& .MuiButton-root": {
      borderRadius: "10px",
      fontWeight: "500",
      width: full ? "100%" : width ? width : "127px",
      height: height ? height : "2.7vw",
      textTransform: "capitalize",
      fontFamily: "var(--font-i_r)",
      justifyContent: logo ? "space-around" : "center",
      color: theme.palette.grey[200],
      [theme.breakpoints.down("sm")]: {
        width: full ? "100%" : width ? width : "90px",
      },
      "&:hover": {
        backgroundColor: "transparent",
        borderColor: theme.palette.button.default,
      },
    },

    "& .MuiButton-outlined": {
      background: "transparent",
      borderColor: theme.palette.secondary[300],
      color: theme.palette.secondary[900],
    },
    "& .MuiTypography-root": {
      fontWeight: "600",
      textTransform: "capitalize",
      fontFamily: "var(--font-f_r)",
      fontSize: "22px",
      color: theme.palette.grey[200],
      [theme.breakpoints.down("sm")]: {
        fontSize: "22px",
      },
    },
  };
});

const CustomButton = ({
  title,
  onClick,
  className,
  type,
  size,
  full,
  outline,
  logoPosition = "right",
  logo,
  loading,
  height,
  width,
  style,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <Root
      style={{}}
      full={full}
      width={width}
      height={height}
      className={className}
      logo={logo}
    >
      <Button
        variant={`${outline ? "outlined" : "contained"}`}
        type={type}
        // size={size}
        onClick={onClick}
        {...rest}
      >
        {logo && logoPosition === "left" && logo}
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Typography>{title}</Typography>
        )}
        {logo && logoPosition === "right" && logo}
      </Button>
    </Root>
  );
};

export default CustomButton;
