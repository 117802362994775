import React from "react";
import { useTheme } from "@emotion/react";
import { Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SocialMediaIcons from "../SocialMediaIcons";
import { useState } from "react";
import Privacy from "./Privacy";
import { useNavigate } from "react-router-dom";
import { farawayLogo } from "../../staticData/images";
const FooterHome = ({ ddu = false }) => {
  const navigate = useNavigate();
  const { palette, ...theme } = useTheme();
  const [privacyPolicy, setPricyPolicy] = useState(false);
  const [checkBox, setCheckBox] = useState(false);

  const handleOpenPricyPolicy = (check) => {
    setPricyPolicy(true);
    setCheckBox(check);
  };
  const handleClosePricyPolicy = () => {
    setPricyPolicy(false);
  };
  return (
    <>
      <Privacy
        show={privacyPolicy}
        onClose={handleClosePricyPolicy}
        title={"DashBO Terms and Conditions"}
        checkBox={checkBox}
      ></Privacy>

      <Box
        sx={{
          background: "#000",
          zIndex: 1000,
          position: "fixed",
          left: 0,
          right: 0,
          bottom: 0,
          paddingTop: 1,
          borderTop: "1px solid #383838",
        }}
      >
        <Toolbar
          variant="dense"
          sx={{
            padding: "0 !important",
            margin: "0 !important",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              "& button": { color: palette.grey[200] },
            }}
          >
            <Box
              sx={{
                width: "100%",
                paddingLeft: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                style={{
                  paddingLeft: "2vw",
                  zIndex: "10000 !important",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                  }}
                >
                  <img
                    style={{
                      paddingLeft: "8px",
                      height: "2.2vw",
                      width: "auto",
                    }}
                    src="https://storage.googleapis.com/3d-container/inceptivestudios.png"
                    alt="inceptive studios company "
                    loading="lazy"
                  />
                  <img
                    style={{
                      paddingLeft: "8px",
                      height: "1.8vw",
                      width: "auto",
                    }}
                    src="https://storage.googleapis.com/3d-container/icons/MADE%20BY%20APE.png"
                    alt="made by ape coin"
                    loading="lazy"
                  />
                </Box>

                <Typography
                  sx={{
                    textAlign: "center",
                    fontFamily: "var(--font-i_r)",
                    fontSize: "0.4vw",
                    color: "#C4C4C4",
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "10px",
                    },
                  }}
                >
                  © 2024 Inceptive Studio All rights reserved.
                </Typography>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "var(--font-i_r)",
                    color: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    fontSize: {
                      xl: "1em",
                      lg: "0.7em",
                      md: "0.4em",
                      xs: "0.8em",
                      sm: "0.8em",
                    },

                    marginRight: "8px",
                  }}
                >
                  Building On
                </Typography>
                <div
                  style={{
                    height: "18px",
                    width: "1px",
                    background: "#C4C4C4",
                    // border: "1px solid #fff",
                  }}
                />
                <img
                  style={{
                    paddingLeft: "24px",
                    height: "1.3vw",
                    width: "auto",
                  }}
                  variant="square"
                  src="https://storage.googleapis.com/3d-container/ApechainHighRes.svg"
                  alt="Ape chain"
                  loading="lazy"
                />
                <img
                  style={{
                    paddingLeft: "24px",
                    height: "1.3vw",
                    width: "auto",
                  }}
                  src="https://storage.googleapis.com/3d-container/Arbitrum.png"
                  alt="Arbitrum"
                  loading="lazy"
                />
                <img
                  style={{
                    paddingLeft: "24px",
                    height: "1.3vw",
                    width: "auto",
                  }}
                  src="https://storage.googleapis.com/3d-container/Unity%20logo.png"
                  alt="made by unity"
                  loading="lazy"
                />
                <img
                  style={{
                    paddingLeft: "24px",
                    height: "1.3vw",
                    width: "auto",
                  }}
                  src="https://storage.googleapis.com/3d-container/Powered%20By%20Ape%20Coin%20White%20(1).png"
                  alt="made by ape coin"
                  loading="lazy"
                />
                {ddu && (
                  <img
                    style={{
                      paddingLeft: "24px",
                      width: "5vw",
                      cursor: "pointer",
                      height: "auto",
                    }}
                    onClick={() => {
                      window.open("https://create.faraway.com/", "_blank");
                    }}
                    src={farawayLogo}
                    alt="made by ape coin"
                    loading="lazy"
                  />
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "4vh",
                }}
              >
                <SocialMediaIcons ddu={ddu} username="DashBoUGC" />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    // onClick={() => {
                    //   !ddu && navigate("/faq");
                    // }}
                    sx={{
                      verticalAlign: "center",
                      cursor: "pointer",
                      paddingLeft: "24px",
                      fontFamily: "var(--font-i_r)",
                      color: "#fff",
                      opacity: 0.7,
                      fontSize: {
                        xl: "0.9em",
                        lg: "0.5em",
                        md: "0.4em",
                        xs: "0.7em",
                        sm: "0.7em",
                      },
                    }}
                  >
                    FAQ
                  </Typography>
                  <Box
                    style={{
                      height: "12px",
                      width: "1px",
                      marginLeft: "24px",
                      background: "#C4C4C4",
                      // border: "1px solid #fff",
                    }}
                  />

                  <Typography
                    // onClick={() => !ddu && handleOpenPricyPolicy(false)}
                    sx={{
                      verticalAlign: "center",
                      cursor: "pointer",
                      paddingLeft: "24px",
                      // padding: "0px 12px",
                      fontFamily: "var(--font-i_r)",
                      color: "#fff",
                      opacity: 0.7,
                      fontSize: {
                        xl: "0.9em",
                        lg: "0.5em",
                        md: "0.4em",
                        xs: "0.7em",
                        sm: "0.7em",
                      },
                    }}
                  >
                    Terms
                  </Typography>
                  <Box
                    style={{
                      height: "12px",
                      width: "1px",
                      marginLeft: "24px",
                      background: "#C4C4C4",
                      // border: "1px solid #fff",
                    }}
                  />

                  <Typography
                    // onClick={() => !ddu && handleOpenPricyPolicy(true)}
                    sx={{
                      verticalAlign: "center",
                      cursor: "pointer",
                      // padding: "0px 12px",
                      fontFamily: "var(--font-i_r)",
                      paddingLeft: "24px",
                      color: "#fff",
                      opacity: 0.7,
                      fontSize: {
                        xl: "0.9em",
                        lg: "0.5em",
                        md: "0.4em",
                        xs: "0.7em",
                        sm: "0.7em",
                      },
                    }}
                  >
                    Privacy
                  </Typography>
                  <Box
                    style={{
                      height: "12px",
                      width: "1px",
                      background: "#000",
                      marginLeft: "24px",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </Box>
    </>
  );
};

export default FooterHome;
