import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stickers: [],
  activeSticker: {},
};

const stickerSlice = createSlice({
  name: "stickerSlice",
  initialState,
  reducers: {
    setActiveSticker: (state, { payload }) => {
      state.activeSticker = payload;
    },
    setDeleteActiveSticker: (state) => {
      state.activeSticker = {};
    },
    setUpdatedSticker: (state, { payload }) => {
      const index = state.stickers?.findIndex((obj) => obj.id === payload.id);
      if (index !== -1) {
        // Use the spread operator to update the object at the found index
        state.stickers[index] = { ...state.stickers[index], ...payload };
      }
    },
    setNewSticker: (state, { payload }) => {
      state.stickers.push(payload);
    },
    setRemoveSticker: (state, { payload }) => {
      state.stickers = payload;
    },
  },
});

export const {
  setActiveSticker,
  setUpdatedSticker,
  setNewSticker,
  setRemoveSticker,
  setDeleteActiveSticker,
} = stickerSlice.actions;

const { reducer } = stickerSlice;
export default reducer;

export const newActiveSticker = (body) => {
  return (dispatch) => {
    dispatch(setActiveSticker(body));
  };
};

export const removeActiveSticker = () => {
  return (dispatch) => {
    dispatch(setDeleteActiveSticker());
  };
};

export const updateSticker = (body) => {
  return (dispatch) => {
    dispatch(setUpdatedSticker(body));
  };
};

export const addNewSticker = (body) => {
  return (dispatch) => {
    dispatch(setNewSticker(body));
  };
};
export const RemoveSticker = (body) => {
  return (dispatch) => {
    dispatch(setRemoveSticker(body));
  };
};
