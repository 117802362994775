import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  animations: [],
  pose: [],
  env: [],
  mba: [],
  EAELoaded: true,
  productLoaded: true,
  expressions: [],
  recording: false,
};

const ModelSlice = createSlice({
  name: "3dModelSlice",
  initialState,
  reducers: {
    setAnimations: (state, { payload }) => {
      state.animations = payload;
    },
    setPoses: (state, { payload }) => {
      state.pose = payload;
    },
    setExpressions: (state, { payload }) => {
      state.expressions = payload;
    },
    setMBA: (state, { payload }) => {
      state.mba = payload;
    },
    setEnv: (state, { payload }) => {
      state.env = payload;
    },
    setEAELoaded: (state, { payload }) => {
      state.EAELoaded = payload;
    },

    setProductLoaded: (state, { payload }) => {
      state.productLoaded = payload;
    },
    setRecording: (state, { payload }) => {
      state.recording = payload;
    },
  },
});

export const {
  setAnimations,
  setEnv,
  setPoses,
  setEAELoaded,
  setProductLoaded,
  setMBA,
  setExpressions,
  setRecording,
} = ModelSlice.actions;
const { reducer } = ModelSlice;
export default reducer;

export const setModelAnimations = (body) => {
  return (dispatch) => {
    dispatch(setAnimations(body));
  };
};
export const setModelPoses = (body) => {
  return (dispatch) => {
    dispatch(setPoses(body));
  };
};
export const setModelExpressions = (body) => {
  return (dispatch) => {
    dispatch(setExpressions(body));
  };
};
export const setModelMBA = (body) => {
  return (dispatch) => {
    dispatch(setMBA(body));
  };
};

export const setModelEnv = (body) => {
  return (dispatch) => {
    dispatch(setEnv(body));
  };
};

export const setModelEAELoaded = (body) => {
  return (dispatch) => {
    dispatch(setEAELoaded(body));
  };
};

export const setModelProductLoaded = (body) => {
  return (dispatch) => {
    dispatch(setProductLoaded(body));
  };
};
