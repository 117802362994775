import Dialog from "@mui/material/Dialog";
import * as React from "react";
import { useTheme, useMediaQuery } from "@mui/material";

function CustomDialog({ onClose, open, body, width }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: "#000",
          border: `1px solid ${theme.palette.button.default}`,
          borderRadius: "0.5vw",
          minHeight: isSmallScreen ? "50vh" : "30vh", // Adjusted minHeight for small screens
          minWidth: isSmallScreen ? "100%" : "50%", // Adjusted minHeight for small screens
        },
        sx: {},
      }}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: width || "100%",
            maxWidth: isSmallScreen ? "100% !important" : "70vw !important", // Adjusted maxWidth for small screens
          },
        },
        backgroundColor: "rgba(0,0,0, 0.6)",
      }}
      onClose={handleClose}
      open={open}
    >
      {body}
    </Dialog>
  );
}

export default CustomDialog;
