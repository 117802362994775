import React from "react";
import { Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "transparent",
  cursor: "pointer",
  transition: "background 0.3s ease",
  "&:hover": {
    backgroundColor: "transparent",
    color: theme.palette.button.default,
  },
  ".MuiTypography-root": {
    // Styling for the text inside the button
    fontWeight: 600,
    textTransform: "capitalize",
    fontFamily: "var(--font-i_r)",
    fontSize: {xl:"1.5em",
    lg:"1.2em",
    md:"1em"


    }, // Responsive font size
    letterSpacing: "0.013em",
    color: theme.palette.grey[200],
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
}));

const HeaderButton = ({ price, currency, imgUrl, sx, onClick }) => {
  const theme = useTheme();

  return (
    <StyledButton
      sx={{ ...sx, padding: "4px 10px" }}
      theme={theme}
      onClick={onClick}
    >
      {price && currency && (
        <Typography
          sx={{ "&hover": theme.palette.button.default }}
        >{`${price} ${currency}`}</Typography>
      )}
      <img
        src={imgUrl}
        alt="Icon"
        style={{ width: "25px", height: "25px", marginLeft: "10px" }} // Updated icon sizing and margin
      />
    </StyledButton>
  );
};

export default HeaderButton;
