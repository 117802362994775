import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

const StatusBox = styled(Box)(({ theme, status }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  color:
    status === "complete"
      ? theme.palette.success.main
      : status === "pending"
      ? theme.palette.error.main
      : "#FFB800",
}));

const transactions = [
  {
    id: 1,
    amount: "0.01 ETH",
    date: "july 5",
    type: "Credit",
    status: "complete",
  },
  {
    id: 2,
    amount: "0.01 ETH",
    date: "2023-07-08",
    type: "Debit",
    status: "pending",
  },
  {
    id: 3,
    amount: "0.01 ETH",
    date: "july 5",
    type: "Credit",
    status: "Processing",
  },
  {
    id: 4,
    amount: "0.01 ETH",
    date: "july 5",
    type: "Credit",
    status: "Processing",
  },

  {
    id: 5,
    amount: "0.01 ETH",
    date: "july 5",
    type: "Credit",
    status: "Processing",
  },

  {
    id: 6,
    amount: "0.01 ETH",
    date: "2023-07-07",
    type: "Credit",
    status: "Processing",
  },

  {
    id: 7,
    amount: "0.01 ETH",
    date: "2023-07-07",
    type: "Credit",
    status: "Processing",
  },
];

const PaymentTransaction = () => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        marginBottom: 2,
        backgroundColor: "#000",
        color: "#fff",
        height: "38vh",
      }}
    >
      <Table>
        <TableBody>
          {transactions.map((transaction) => (
            <TableRow key={transaction.id}>
              <TableCell
                sx={{
                  marginBottom: 2,
                  backgroundColor: "#000",
                  color: "#fff",
                }}
              >
                <img
                  src="https://storage.googleapis.com/3d-container/etherum.png"
                  alt="etherum"
                  srcset=""
                  style={{ marginRight: "10px", marginBottom: "-8px" }}
                  height={"25px"}
                  width={"auto"}
                />
                {transaction.amount}
              </TableCell>
              <TableCell
                sx={{ marginBottom: 2, backgroundColor: "#000", color: "#fff" }}
              >
                {transaction.date}
              </TableCell>
              <TableCell
                sx={{ marginBottom: 2, backgroundColor: "#000", color: "#fff" }}
              >
                {transaction.type}
              </TableCell>
              <TableCell
                sx={{ marginBottom: 2, backgroundColor: "#000", color: "#fff" }}
              >
                <StatusBox status={transaction.status}>
                  <Typography variant="body2" align="center">
                    {transaction.status.charAt(0).toUpperCase() +
                      transaction.status.slice(1)}
                  </Typography>
                </StatusBox>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaymentTransaction;
