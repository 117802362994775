import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { BackButton, Header } from "../../components";
import FooterHome from "../../components/footer/FooterHome";
import "../../index.css";
import { getProjectChildrenBySlug } from "../../services/clientService";
import { useLoader } from "../../context/loaderContext";
import HomeSlider from "./HomeSlider";
import Typography from "@mui/material/Typography";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import MobileFooter from "../../components/MobileFooter";
import MobileHeader from "../../components/MobileHeader";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SliderSkeleton from "../SliderSkeleton";

const CollectionData = () => {
  const theme = useTheme();
  const { clientId, id } = useParams();
  const { showLoader, hideLoader } = useLoader();
  const [projects, setProjects] = useState([]);
  const [slug, setSlug] = useState([]);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getData = async () => {
    try {
      const { slug, projects } = await getProjectChildrenBySlug(id);
      if (projects) {
        setProjects(projects.children);
        setSlug(slug);
      }
    } catch (err) {}
  };
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, [clientId]);

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
      }}
    >
      {isMobile ? <MobileHeader></MobileHeader> : <Header hamburger={true} />}
      <Box
        sx={{
          position: "absolute",
          top: "15%",
          left: "60px",
          zIndex: 1000,
        }}
      >
        <BackButton
          customBack={true}
          onClick={() => {
            navigate("/" + clientId);
          }}
        />
      </Box>
      <Box>
        {projects.length > 0 ? (
          <HomeSlider projects={projects} />
        ) : (
          <Box
            sx={{
              minHeight: "86vh", // Set a minimum height for HomeSlider when projects are empty
            }}
          >
            <Typography
              variant="h1"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#ffffff",
                padding: "155px",
              }}
            ></Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          position: "absolute",
          marginLeft: "20px",
          bottom: { xl: "9%", lg: "7%", md: "0%", sm: "20%", xs: "20%" },
          width: "100%",
          minHeight: "300px",
        }}
      >
        {projects.length > 0 ? (
          <HeroSlider
            home={true}
            projects={projects}
            slug={slug}
            heading="Projects"
          />
        ) : (
          <SliderSkeleton count={isMobile ? 2 : 7} />
        )}
      </Box>
      {isMobile ? <MobileFooter></MobileFooter> : <FooterHome />}
    </Box>
  );
};

export default CollectionData;
const HeroSlider = ({ projects = [], slug }) => {
  const { palette, ...theme } = useTheme();
  const isMobile = useMediaQuery(
    theme.breakpoints.down("sm") || theme.breakpoints.down("xs")
  );

  const navigate = useNavigate();

  const handleNavigate = (project) => {
    navigate(`/${slug}/${project.slugan}`);
  };
  const winWidth = window.innerWidth;
  const responsiveWidth = () => {
    if (winWidth > 1920) {
      return "13vw";
    } else if (winWidth > 1500) {
      return "12.6vw";
    } else if (winWidth > 1000 && winWidth <= 1500) {
      return "15vw";
    } else if (winWidth > 700 && winWidth <= 1000) {
      return "18vw";
    } else if (winWidth > 500 && winWidth <= 700) {
      return "33vw";
    } else if (winWidth <= 500) {
      return "50vw";
    } else {
      return "10vw";
    }
  };
  const Projects = projects.map((project, i) => (
    <Box
      key={i}
      onClick={() => handleNavigate(project)}
      sx={{
        margin: "0.5vw",
        cursor: "pointer",
        width: responsiveWidth(),
        minWidth: responsiveWidth(),
        display: "flex",
        flexDirection: "column",
        backdropFilter: "blur(10px)",
        height: { xl: "21vh", lg: "21vh", md: "24vh", xs: "24vh", sm: "24vh" },
        justifyContent: "center",
        border: "1px solid #95959529",
        p: { xl: 1, lg: 1, md: 1, xs: 2, sm: 2 },
        alignItems: "center",
        borderRadius: "0.5vw",
        transition:
          "transform 0.3s ease, border 0.3s ease, box-shadow 0.3s ease",

        "&:hover": {
          transform: "scale(1.02)",
          border: "1px solid #FFB800",
          boxShadow: "0 4px 8px rgba(255, 184, 0, 0.5)",
          ".text-banner-pro": {
            color: "#FFB800",
          },
        },
      }}
    >
      <img
        src={project.thumbImg}
        alt={projects.name}
        style={{
          height: "100%",
          width: "100%",
          borderRadius: "0.5em",
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            textTransform: "uppercase",
            fontFamily: "var(--font-i_r)",
            width: "100%",
            textAlign: "center",
            padding: "0.4em",
            fontSize: {
              xl: "1.2em",
              lg: "0.7em",
              md: "0.6em",
              xs: "0.7em",
              sm: "0.7em",
            },
            color: palette.grey[200],
          }}
        >
          {project?.name}
        </Typography>
      </Box>
    </Box>
  ));
  const scrollContainerRef = useRef(null);

  const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === "left" ? -200 : 200;
      scrollContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };
  return (
    <Box>
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "2em",
        }}
      >
        <Box>
          <IconButton onClick={() => scroll("left")} color="primary">
            <ChevronLeftIcon size={isMobile ? "12px" : "1vw"} />
          </IconButton>
          <IconButton onClick={() => scroll("right")} color="primary">
            <ChevronRightIcon size={isMobile ? "12px" : "1vw"} />
          </IconButton>
        </Box>
      </Grid>
      <Box
        ref={scrollContainerRef}
        sx={{
          display: "flex",
          height: "28vh",
          width: "100%",
          overflowX: "hidden",
        }}
      >
        {Projects}
      </Box>
    </Box>
  );
};
