import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  balance: 50, // Initial balance value
};

const balanceSlice = createSlice({
  name: "balanceSlice",
  initialState,
  reducers: {
    decreaseBalance: (state) => {
      if (state.balance > 0) state.balance -= 1;
    },
  },
});

export const { decreaseBalance } = balanceSlice.actions;

export const selectBalance = (state) => state.balance.balance;

export default balanceSlice.reducer;
