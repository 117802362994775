import React, { useState } from "react";
import { Box } from "@mui/material";
import HeaderButton from "./HeaderButton";
import { selectBalance } from "../../slice/balanceSlice";
import { useSelector } from "react-redux";
import LoginDialog from "../LoginDialog";
import Withdraw from "../../pages/pricing/payment/Withdraw";
const ButtonGroup = () => {
  const balance = useSelector(selectBalance);
  const [loginPopup, setLoginPopup] = useState(false);
  const [withdrawPopup, setWithdrawPopup] = useState(false);
  const [mode, setMode] = useState("login");
  const handleLoginPopup = () => {
    setLoginPopup(true);
  };
  const handleWalletPopup = () => {
    setWithdrawPopup(true);
  };
  const handleWithdrawClose = () => {
    setWithdrawPopup(false);
  };
  return (
    <>
      <Withdraw
        show={withdrawPopup}
        onClose={handleWithdrawClose}
        title="Bridge"
      ></Withdraw>
      <Box
        sx={{
          backdropFilter: "blur(10px)",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          padding: "8px",
          borderRadius: "4px",
          margin: "auto",
          maxWidth: "600px",
          marginRight: "8px",
        }}
      >
        <HeaderButton
          onClick={handleWalletPopup}
          price={balance}
          currency="Credits"
          imgUrl="https://storage.googleapis.com/3d-container/BalanceIcon.png"
        />
        <HeaderButton
          onClick={handleWalletPopup}
          price={100}
          currency="ape"
          imgUrl={
            "https://ishrostorage.blob.core.windows.net/container-3d/apeCoinLogo.png"
          }
        />
        <HeaderButton
          imgUrl="https://storage.googleapis.com/3d-container/reconnect.png"
          onClick={handleLoginPopup}
        />
      </Box>
      <LoginDialog
        loginPopup={loginPopup}
        setLoginPopup={setLoginPopup}
        mode={mode}
        setMode={setMode}
      />
    </>
  );
};

export default ButtonGroup;
