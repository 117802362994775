import React from "react";
import Skeleton from "@mui/material/Skeleton";
import { Box } from "@mui/system";
import "./SliderSkeleton.css";

const SliderSkeleton = ({ count }) => {
  const skeletons = Array.from(new Array(count)).map((_, index) => (
    <Box className="card" key={index}>
      <Box className="card-img skeleton">
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </Box>
      <Box className="card-body">
        <Skeleton variant="text" className="card-intro skeleton" />
      </Box>
    </Box>
  ));

  return <Box className="container">{skeletons}</Box>;
};

export default SliderSkeleton;
