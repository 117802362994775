import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  loading: true,
  error: false,
  user: [],
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
    },
    setUser: (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.errormsg = "";
      state.user = payload;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
  },
});

export const { setLoading, setUser, setError } = authSlice.actions;

const { reducer } = authSlice;
export default reducer;
