import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  texts: [],
  activeText: {},
};

const textSlice = createSlice({
  name: "textSlice",
  initialState,
  reducers: {
    setActiveText: (state, { payload }) => {
      state.activeText = payload;
    },
    setDeleteActiveText: (state) => {
      state.activeText = {};
    },
    setUpdatedText: (state, { payload }) => {
      const index = state.texts?.findIndex((obj) => obj.id === payload.id);
      if (index !== -1) {
        // Use the spread operator to update the object at the found index
        state.texts[index] = { ...state.texts[index], ...payload };
      }
    },
    setNewText: (state, { payload }) => {
      state.texts.push(payload);
    },
    setRemoveText: (state, { payload }) => {
      state.texts = payload;
    },
  },
});

export const {
  setActiveText,
  setUpdatedText,
  setNewText,
  setRemoveText,
  setDeleteActiveText,
} = textSlice.actions;

const { reducer } = textSlice;
export default reducer;

export const newActiveText = (body) => {
  return (dispatch) => {
    dispatch(setActiveText(body));
  };
};

export const removeActiveText = () => {
  return (dispatch) => {
    dispatch(setDeleteActiveText());
  };
};

export const updateText = (body) => {
  return (dispatch) => {
    dispatch(setUpdatedText(body));
  };
};

export const addNewText = (body) => {
  return (dispatch) => {
    dispatch(setNewText(body));
  };
};
export const RemoveText = (body) => {
  return (dispatch) => {
    dispatch(setRemoveText(body));
  };
};
