import { Box, Button, Grid, Typography } from "@mui/material";
import { MdInfoOutline } from "react-icons/md";

const WithdrawDialog = ({ flag }) => (
  <Grid container height="42vh">
    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
      <Typography variant="body1" fontWeight="600" color="#fff">
        From
      </Typography>
      <Typography
        variant="body1"
        fontWeight="600"
        color="#fff"
        sx={{
          padding: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          backgroundColor: "#43400B",
          borderRadius: "0.5vw",
          margin: ".5vw 1vw .5vw 0",
        }}
      >
        <img
          src={
            flag
              ? "https://storage.googleapis.com/3d-container/etherum.png"
              : "https://ishrostorage.blob.core.windows.net/container-3d/apeCoinLogo.png"
          }
          alt="etherum"
          srcset=""
          style={{ marginRight: "10px" }}
          height={"30px"}
          width={"auto"}
        />
        {flag ? "Ethereum" : "ApeCoin"}
      </Typography>
      <Typography
        variant="body1"
        fontWeight="600"
        color="#fff"
        sx={{
          padding: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          border: "1px solid #2C2C2C",
          height: "8vh",
          borderRadius: "0.5vw",
          margin: ".5vw 1vw .5vw 0",
        }}
      >
        0.000
      </Typography>
      <Typography
        variant="body1"
        fontWeight="600"
        color="#fff"
        sx={{
          padding: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          borderRadius: "0.5vw",
          margin: ".5vw 1vw .5vw 0",
        }}
      >
        <Typography sx={{ marginRight: "10px", color: "#475467" }}>
          Balance
        </Typography>
        0.000
        <Typography sx={{ marginLeft: "10px", color: "#FFB800" }}>
          Max
        </Typography>
      </Typography>
    </Grid>
    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
      <Typography variant="body1" fontWeight="600" color="#fff">
        To
      </Typography>
      <Typography
        variant="body1"
        fontWeight="600"
        color="#fff"
        sx={{
          padding: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          backgroundColor: "#43400B",
          borderRadius: "0.5vw",
          margin: ".5vw 1vw .5vw 0",
        }}
      >
        <img
          src={
            !flag
              ? "https://storage.googleapis.com/3d-container/etherum.png"
              : "https://ishrostorage.blob.core.windows.net/container-3d/apeCoinLogo.png"
          }
          alt="etherum"
          srcset=""
          style={{ marginRight: "10px" }}
          height={"30px"}
          width={"auto"}
        />
        {!flag ? "Ethereum" : "ApeCoin"}
      </Typography>
      <Typography
        variant="body1"
        fontWeight="200"
        color="#fff"
        sx={{
          padding: 1,
          display: "flex",
          fontSize: "0.8vw",
          alignItems: "center",
          justifyContent: "flex-start",
          border: "1px solid #545454",
          backgroundColor: "#545454",
          height: "8vh",
          borderRadius: "0.5vw",
          margin: ".5vw 1vw .5vw 0",
          opacit: "0.5",
        }}
      >
        You will receive 0.00 ETH + Yield + Points
      </Typography>
    </Grid>
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Button
        sx={{
          width: "100%",
          backgroundColor: "transparent",
          color: "#fff",
          border: "1px solid #FFB800",
          borderRadius: "0.5vw",
          "&:hover": {
            backgroundColor: "#FFB800",
            color: "#000",
          },
        }}
        variant="contained"
      >
        Add apeChain to wallet
      </Button>
      <Button
        sx={{
          width: "100%",
          backgroundColor: "#FFB800",
          color: "#000",
          border: "1px solid #FFB800",
          marginTop: ".5vh",
          borderRadius: "0.5vw",
          "&:hover": {
            backgroundColor: "#FFB800",
            color: "#000",
          },
        }}
        variant="contained"
      >
        Bridge
      </Button>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginTop: "1vh",
          padding: "1vh",
          backgroundColor: "#2C2C2C",
          borderRadius: "0.5vw",
          position: "relative",
        }}
      >
        <img
          src="https://storage.googleapis.com/3d-container/shim.png"
          alt=""
          srcset=""
        />
        <Typography sx={{ marginLeft: "10px", color: "#C4C4C4" }}>
          Bridging to ApeChain takes 30s
        </Typography>
        <Box
          sx={{
            marginLeft: "37%",
            color: "#C4C4C4",
            postion: "absolute",
            justifyContent: "flex-end",
            display: "flex",
            alignItems: "center",
            right: 0,
            top: 0,
          }}
        >
          <Typography
            sx={{
              color: "#C4C4C4",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginRight: "10px",
            }}
          >
            Save 10 mins
          </Typography>
          <MdInfoOutline size={22} />
        </Box>
      </Box>
    </Grid>
  </Grid>
);
export default WithdrawDialog;
