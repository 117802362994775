// Loader.js
import React from "react";
import "./Loader.css";

const Loader = ({ width = "100vw" }) => {
  return (
    <div style={{ width: width }} className="loader-overlay">
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
